import { memo } from "react";
import "./SmartDPHotelDescriptionLoader.scss";
import LoaderBar from "app/pages/.shared/LoaderBar/LoaderBar";

const SmartDPHotelDescriptionLoader = () => {
	return (
		<div className="sdp-hotel-description-loader">
			<div className="sdp-hotel-description-loader__header">
				<LoaderBar />
				<LoaderBar />
			</div>
			<img
				className="sdp-hotel-description-loader__photo"
				src="/static/icons/quote_img.png"
			/>
			<img src="/static/icons/quote_description.svg" />
		</div>
	);
};

export default memo(SmartDPHotelDescriptionLoader);
