import { useEffect, useState } from "react";
import { parseQueries } from "app/utils/routerUtils";
import { useLocation } from "react-router-dom";

/**
 * Read the printMode query param in url to detect if we are generating a pdf.
 * printMode = true can be used to expand all more details for example.
 * You should call startPrint
 * @see https://perfectstay.atlassian.net/wiki/spaces/FRON/pages/1967161345/G+n+rer+un+PDF+de+la+fiche+produit
 */
export default () => {
	const [isPrintMode, setPrintMode] = useState(false);
	const { search } = useLocation();

	useEffect(() => {
		const queries = parseQueries(search);
		if (queries.printMode) {
			setPrintMode(true);
		}
	}, [search]);

	return isPrintMode;
};
