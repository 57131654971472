import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./Transport.scss";

const Transport = ({ description }) => {
	return (
		<div className="transport">
			<h4 className="transport__headline">
				<FormattedMessage id="useful.information.transports.headline" />
			</h4>
			<div className="transport__description">
				<Paragraphs paragraphs={description} enableHTMLFormatting={true} />
			</div>
		</div>
	);
};

Transport.propTypes = {
	description: PropTypes.arrayOf(PropTypes.string),
};

Transport.defaultProps = {
	description: [],
};

export default memo(Transport);
