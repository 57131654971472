import { RESET_PRINT, START_PRINT } from "app/actionTypes";

export const startPrint = () => {
	return {
		type: START_PRINT,
	};
};

export const resetPrint = () => {
	return {
		type: RESET_PRINT,
	};
};
