import { memo } from "react";
import PropTypes from "prop-types";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg";
import Paragraphs from "app/pages/.shared/Paragraphs";
import "./AdditionalSection.scss";

const TOUR_ITINERARY_MEDIA_SIZES = {
	small: { width: 768 },
	medium: { width: 978 },
	large: { height: 208, width: 581 },
	xlarge: { height: 208, width: 581 },
};

const AdditionalSection = ({ title, paragraphs = [], photos = [] }) => {
	const photo = photos[0] || {};

	return (
		<div className="additional-section">
			{title && <h4 className="additional-section__label">{title}</h4>}
			{photo.url && (
				<div className="additional-section__image">
					<ProgressiveImg
						sizes={TOUR_ITINERARY_MEDIA_SIZES}
						src={photo.url}
						alt={photo.alt}
						thumbnail={photo.thumbnail}
					/>
				</div>
			)}
			<div className="additional-section__intro">
				<Paragraphs paragraphs={paragraphs} enableHTMLFormatting={true} />
			</div>
		</div>
	);
};

AdditionalSection.propTypes = {
	title: PropTypes.string,
	paragraphs: PropTypes.array,
	photos: PropTypes.array,
};

export default memo(AdditionalSection);
