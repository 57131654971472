import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import "./Formalities.scss";
import Paragraphs from "app/pages/.shared/Paragraphs";

/* eslint react/no-danger: 0 */
const Formalities = ({ intro, vaccine, visa, identity, country }) => {
	return (
		<div className="formalities">
			<h4 className="formalities__headline">
				<FormattedMessage
					id="useful.information.formalities.headline"
					values={{ country: country }}
				/>
			</h4>
			<div className="formalities__intro" data-testid="formalities-intro">
				<Paragraphs paragraphs={intro} enableHTMLFormatting />
			</div>
			<div className="formalities-list">
				{vaccine && (
					<div className="formalities-list__item" data-testid="formalities-vaccine">
						<div className="formalities-list__headline">
							<FormattedMessage id="useful.informtion.vaccine.title" />
						</div>
						<Paragraphs paragraphs={vaccine} enableHTMLFormatting />
					</div>
				)}

				{visa && (
					<div className="formalities-list__item" data-testid="formalities-visa">
						<div className="formalities-list__headline">
							<FormattedMessage id="useful.informtion.visa.title" />
						</div>
						<Paragraphs paragraphs={visa} enableHTMLFormatting />
					</div>
				)}

				{identity && (
					<div className="formalities-list__item" data-testid="formalities-identity">
						<div className="formalities-list__headline">
							<FormattedMessage id="useful.informtion.identity.title" />
						</div>
						<Paragraphs paragraphs={identity} enableHTMLFormatting />
					</div>
				)}
			</div>
		</div>
	);
};

Formalities.propTypes = {
	country: PropTypes.string,
	vaccine: PropTypes.string,
	intro: PropTypes.string,
	visa: PropTypes.string,
	identity: PropTypes.string,
};

export default memo(Formalities);
