import PropTypes from "prop-types";
import { memo } from "react";
import { FormattedMessage } from "react-intl";
import ProgressiveImg from "app/pages/.shared/ProgressiveImg.jsx";
import "./SmartDPPatchwork.scss";

const PHOTO_SIZES = [
	{ width: 372, height: 202 },
	{ width: 185, height: 99 },
	{ width: 185, height: 99 },
];

const SmartDPPatchwork = ({ photos, onShowMore }) => {
	const photosNodes = photos.slice(0, 3).map((photo, index) => {
		const isLastItem = index === 2;

		const imgSizes = {
			large: PHOTO_SIZES[index],
			xlarge: PHOTO_SIZES[index],
		};

		return (
			<div key={index} className="smartDPPpatchwork__item">
				<ProgressiveImg
					sizes={imgSizes}
					src={photo.url}
					thumbnail={photo.thumbnail}
					alt={photo.alt}
					cloudinaryOptions={["c_fill"]}
				/>
				{isLastItem && (
					<div className="smartDPPpatchwork__more">
						<FormattedMessage
							id="sdp.patchwork.photos.label"
							values={{ numbers: photos.length - 3 }}
						/>
					</div>
				)}
			</div>
		);
	});

	return (
		<div className="smartDPPpatchwork" onClick={onShowMore}>
			{photosNodes}
		</div>
	);
};

SmartDPPatchwork.propTypes = {
	photos: PropTypes.array,
	onShowMore: PropTypes.func,
};

SmartDPPatchwork.defaultProps = {
	photos: [],
	onShowMore: () => {},
};

export default memo(SmartDPPatchwork);
